<template>
  <div class="home">

    <div class="container">
      <figure class="image is-3by1">
        <img src="../assets/Startseite.jpg">
      </figure>
    </div>

    <div class="container is-info" style="margin-top:40px">

      <p class="is-size-4 has-text-centered" style="font-family: 'Lato Thin'; font-weight: bold; padding-left:10%; padding-right:10%;">
        Wir sind eine christliche Gemeinde von Menschen, die Gott lieben möchten mit ganzem Herzen, ganzem Einsatz, ganzem Verstand und all unserer Kraft - und unsere Mitmenschen wie uns selbst.<br/>
        (Nach Lukas 10, 27)
          <br/><br/>
          <router-link to="/about"  class="button is-link is-medium">Lerne uns kennen</router-link>
      </p>
    </div>

    <section class="section">
      <div class="container">

          <div class="column">

              <div class="columns is-multiline">
                  <div v-for="veranstaltung in veranstaltungen" v-bind:key="veranstaltung.id"
                       class="column is-one-third">
                      <KalenderVeranstaltung :titel=veranstaltung.titel
                                             :beginn=veranstaltung.beginn
                                             :ende=veranstaltung.ende
                                             ort="Reinhold-Frank-Straße 44a, 76133 Karlsruhe"
                                             :beschreibung=veranstaltung.beschreibung
                                             :image=veranstaltung.image
                                             :ansprechpartner=veranstaltung.ansprechpartner
                                             :ansprechpartnerBild=veranstaltung.ansprechpartner_bild
                                             :ansprechpartnerMail=veranstaltung.ansprechpartner_mail
                      />
                  </div>
              </div>
          </div>

      </div>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import KalenderVeranstaltung from '@/components/KalenderVeranstaltung.vue'

export default {
  name: 'Home',
  components: {
      KalenderVeranstaltung
  },
  data () {
    return {
      veranstaltungen: null
    }
  },
  mounted () {
    this.$cgrest
            .get('/startseite')
            .then(response => (this.veranstaltungen = response.data))
  },
}
</script>
